import { useState } from "react";
import { Switch } from "@headlessui/react";
import { useFormikContext } from "formik";

export default function AppFormSwitch({ name, text }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="flex items-center pt-2">
      <Switch
        as="button"
        id={name}
        checked={values[name]}
        onChange={(value) => setFieldValue(name, value)}
        className={`${values[name] ? "bg-dark-blue" : "bg-gray-300"}
          relative inline-flex flex-shrink-0 h-[28px] w-[56px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${values[name] ? "translate-x-0" : "translate-x-7"}
            pointer-events-none inline-block h-[24px] w-[24px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
        />
      </Switch>
      <span className={`${values[name] ? "text-dark-blue" : "text-dark"} mx-2`}>
        {text}
      </span>
    </div>
  );
}
